import React from 'react';
import { Box, Text } from '@strikelabs/luna';
import { Slide } from 'pure-react-carousel';

import { Container } from 'components/common';
import Carousel from 'components/Carousel';

import Owl from './Images/owl.png';
import Aquarium from './Images/aquarium.png';
import Library from './Images/belle-library.png';
import Disco from './Images/disco.png';
import MeditationRoom from './Images/meditation-room.png';
import RooftopTerrace from './Images/rooftop-terrace.png';
import SensoryEquipment from './Images/sensory-equipment.png';
// import TikiBar from './Images/tiki-bar.png';
import TreeHouse from './Images/treehouse.png';
import WoodenCabin from './Images/wooden-cabin.png';

const CompetitionEntriesCarousel = () => {
  return (
    <Box backgroundColor="backgroundLight" pt={[5, 5, 5, 6]} pb={[5, 5, 5, 2]}>
      <Container>
        <Carousel
          currentSlide={0}
          totalSlides={9}
          showDots
          isPlaying
          infinite
          isIntrinsicHeight
          naturalSlideWidth={220}
          naturalSlideHeight={220}
          visibleAtMobile={1}
          visibleAtSmallTablet={2}
          visibleAtDesktop={4}
          visibleAtGiant={4}
          visibleAtSuper={4}
        >
          <Slide index={0} key={0}>
            <Box textAlign="center">
              <img src={Owl} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                Owl Statues
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide>
          {/*  <Slide index={1} key={1}>
            <Box textAlign="center">
              <img src={TikiBar} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                Tiki Bar
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide> */}
          <Slide index={1} key={1}>
            <Box textAlign="center">
              <img src={TreeHouse} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                Rapunzel Treehouse
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Winner
              </Text>
            </Box>
          </Slide>
          <Slide index={2} key={2}>
            <Box textAlign="center">
              <img src={SensoryEquipment} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                Sensory Equipment
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide>
          <Slide index={3} key={3}>
            <Box textAlign="center">
              <img src={Disco} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                Disco Dancefloor
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide>
          <Slide index={4} key={4}>
            <Box textAlign="center">
              <img src={MeditationRoom} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                Garden Mediation Room
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide>
          <Slide index={5} key={5}>
            <Box textAlign="center">
              <img src={Aquarium} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                Aquarium Bathroom
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide>
          <Slide index={6} key={6}>
            <Box textAlign="center">
              <img src={Library} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                &ldquo;Belle&rdquo; Library
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide>
          <Slide index={7} key={7}>
            <Box textAlign="center">
              <img src={WoodenCabin} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                &ldquo;Après-ciate&rdquo; wooden cabin
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide>
          <Slide index={8} key={8}>
            <Box textAlign="center">
              <img src={RooftopTerrace} alt="slide" />
              <Text as="p" styleType="caption" ext bold color="#000" mt={0}>
                Urban Roof Terrace
              </Text>
              <Text as="p" ext styleType="caption" color="#000">
                Runner Up
              </Text>
            </Box>
          </Slide>
        </Carousel>
      </Container>
    </Box>
  );
};

export default CompetitionEntriesCarousel;
