import React from 'react';
import { Flex, Box, Icon, Text, Highlight } from '@strikelabs/luna';

import { Outer, Inner, TinieQuote } from './style';

const Intro = () => {
  return (
    <Outer>
      <Inner>
        <Flex justifyContent="center" alignItems="center">
          <Box width={[3 / 10, 3 / 10, 3 / 10, 3.5 / 10, 2.5 / 10]}>
            <Icon type="strikeLogo" width="100%" theme="dark" />
          </Box>
          <Text as="h2" ext ml={2} mt={0}>
            <Highlight active> Free the Fees</Highlight>
          </Text>
        </Flex>
        <Text styleType="body" ext mt={[4, 4, 4, 6]}>
          The fund has closed and the winners have been chosen! Thank you so
          much for all of your entries &mdash; and special thanks to Tinie
          Tempah for joining us and helping to judge the winner. We loved seeing
          your creative and thoughtful entries, dreaming up what you would have
          done if you hadn&apos;t had to spend your cash on estate agent fees.
        </Text>
        <Flex justifyContent="center" mt={[4, 4, 4, 5]}>
          <Box width={[1, 1, 1, 9 / 10]}>
            <TinieQuote noTrailingQuotationMark>
              <Box textAlign="center">
                <Text as="h3" ext bold>
                  I&apos;ve always questioned why we have to pay so much to the
                  estate agent when that money could be spent on improving our
                  home instead &mdash; especially as renovation is my passion
                  when it comes to property,&rdquo; Tinie said. &ldquo;I love
                  seeing what levels of creativity people have when it comes to
                  their homes. If I could&rsquo;ve saved the estate agent fees
                  for the houses I&rsquo;ve sold, by now I would&rsquo;ve
                  created a full-on spa with a steam room and sauna for my
                  home.&rdquo;
                </Text>
              </Box>
            </TinieQuote>
          </Box>
        </Flex>
        <Text styleType="body" ext mt={5}>
          We&apos;ve now been in touch with all of the winners &mdash; the 9
          runners up who each will receive &pound;100 cash to buy something
          outlandish and amazing. And, of course, the one Full Commission grand
          prize winner, who&apos;s being reimbursed the average cost of an
          estate agent: a whopping &pound;4,050 cash!
        </Text>
      </Inner>
    </Outer>
  );
};

export default Intro;
