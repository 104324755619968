import React, { useMemo } from 'react';
import {
  Box,
  Back,
  Loader,
  MountTransition,
  useIsMedia,
  Flex,
} from '@strikelabs/luna';
import { identifier, useWindowSize } from '@strikelabs/pax';
import PropTypes from 'prop-types';
import { CarouselProvider, Slider } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

import {
  ULTRAWIDE_WIDTH,
  WIDESCREEN_WIDTH,
  DESKTOP_WIDTH,
} from 'constants/widths';
import useMount from 'hooks/useMount';

import { ArrowLeft, ArrowRight, ForwardArrow, ArrowWrapper } from './style';
import Dots from './Dots';

const Carousel = ({
  children,
  visibleSlides,
  currentSlide,
  showControls,
  totalSlides,
  showDots,
  visibleAtMobile,
  visibleAtSmallTablet,
  visibleAtDesktop,
  visibleAtGiant,
  visibleAtSuper,
  invert,
  ...rest
}) => {
  const { width } = useWindowSize(true);
  const media = useIsMedia();
  const { mounted } = useMount();

  const getVisibleSlides = useMemo(() => {
    if (!mounted) {
      return;
    }
    if (width >= ULTRAWIDE_WIDTH) {
      return visibleAtSuper ? visibleAtSuper : 2.4;
    }
    if (width >= WIDESCREEN_WIDTH) {
      return visibleAtSuper ? visibleAtSuper : 2.4;
    }
    if (width >= DESKTOP_WIDTH) {
      return visibleAtSuper ? visibleAtSuper : 2.4;
    }
    if (media.giant) {
      return visibleAtGiant ? visibleAtGiant : 2;
    }
    if (media.desktop) {
      return visibleAtDesktop ? visibleAtDesktop : 2;
    }
    if (media.tablet) {
      return 2;
    }
    if (media.smallTablet) {
      return visibleAtSmallTablet ? visibleAtSmallTablet : 1.3;
    }
    return visibleAtMobile ? visibleAtMobile : 1;
  }, [
    mounted,
    width,
    media,
    visibleAtDesktop,
    visibleAtGiant,
    visibleAtSmallTablet,
    visibleAtMobile,
    visibleAtSuper,
  ]);

  return (
    <Flex overflow="hidden">
      <CarouselProvider
        {...rest}
        visibleSlides={visibleSlides ? visibleSlides : getVisibleSlides}
        hasMasterSpinner={!mounted}
        totalSlides={totalSlides}
      >
        <Slider
          style={{ overflow: 'visible' }}
          {...identifier({ name: 'slider', requireId: false })}
          spinner={() => <Loader fullSize />}
        >
          <MountTransition>{children}</MountTransition>
        </Slider>
        {!!showControls && (
          <ArrowWrapper>
            <Box>
              <ArrowLeft>
                <Back chevron as="div" invert={invert} />
              </ArrowLeft>
            </Box>
            <Box>
              <ArrowRight>
                <ForwardArrow>
                  <Back chevron as="div" invert={invert} />
                </ForwardArrow>
              </ArrowRight>
            </Box>
          </ArrowWrapper>
        )}
        {showDots && (
          <Flex justifyContent="center" mt={3}>
            <Dots
              value={currentSlide}
              slides={totalSlides}
              fill="darkMenuHighlight"
              spacing="xs"
            />
          </Flex>
        )}
      </CarouselProvider>
    </Flex>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  visibleSlides: PropTypes.number,
  currentSlide: PropTypes.number,
  showControls: PropTypes.bool,
  showDots: PropTypes.bool,
  totalSlides: PropTypes.number.isRequired,
  visibleAtMobile: PropTypes.number,
  visibleAtSmallTablet: PropTypes.number,
  visibleAtDesktop: PropTypes.number,
  visibleAtGiant: PropTypes.number,
  visibleAtSuper: PropTypes.number,
  invert: PropTypes.bool,
};

Carousel.defaultProps = {
  showControls: true,
  showDots: false,
  visibleAtMobile: null,
  visibleAtSmallTablet: null,
  visibleAtDesktop: null,
  visibleAtGiant: null,
  visibleAtSuper: null,
  invert: false,
};

export default Carousel;
