import styled, { css } from 'styled-components';
import { ButtonBack, ButtonNext } from 'pure-react-carousel';
import { rem } from 'polished';

export const ForwardArrow = styled.div`
  transform: rotate(180deg);
`;

export const ArrowBase = css`
  background: 0;
  border: 0;
  padding: 0;
  transition: all 0.2s ease-in-out;
  padding: ${({ theme }) => `0 ${theme.defaults.gutterSpacing}`};
  opacity: 1;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0;

    button {
      cursor: default;
    }
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.maxWidth};
  width: 100%;
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing.rg};
  padding-right: ${({ theme }) => theme.spacing.sm};
`;

export const ArrowRight = styled(ButtonNext)`
  ${ArrowBase}

  &:hover {
    transform: translateX(1px);
  }
`;

export const ArrowLeft = styled(ButtonBack)`
  ${ArrowBase}
  padding: 0 ${rem(8)};
  transform: translateY(-3px);

  &:hover {
    transform: translate(-1px, -3px);
  }
`;
