import React from 'react';
import { identifier } from '@strikelabs/pax';
import PropTypes from 'prop-types';

import { Circle, Container } from './style';

const Dots = ({ slides, size, spacing, fill, id }) => (
  <Container
    role="tablist"
    {...identifier({ name: 'dot-controls', id, requireId: false })}
    width="auto"
  >
    {[...Array(slides)].map((slide, idx) => (
      <Circle
        size={size}
        spacing={spacing}
        fill={fill}
        key={slide}
        icon="circle"
        slide={idx}
      />
    ))}
  </Container>
);

Dots.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'default']),
  slides: PropTypes.number.isRequired,
  fill: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spacing: PropTypes.oneOf(['xs', 'sm', 'md']),
};

Dots.defaultProps = {
  size: 'default',
  fill: 'lightIcon',
  spacing: 'xs',
};

export default Dots;
