import styled from 'styled-components';
import { BlockQuote } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled.div`
  padding: ${({ theme }) => `${theme.spacing.rg} 0`};
  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.xl};
    padding-bottom: ${({ theme }) => theme.spacing.xs};
  }
`;

export const Inner = styled(Container)`
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
  }
`;

export const TinieQuote = styled(BlockQuote)`
  // custom font size
  font-size: ${rem(22)};
`;
