import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import {
  Intro,
  Video,
  CompetitionEntriesCarousel,
} from 'components/Pages/FreeTheFees/Sections';
import BrandImg from 'images/strike-brand.png';

const FreeTheFees = () => {
  return (
    <Layout>
      <SEO
        title="Free the Fees Fund | Strike"
        description="Claim back some of the cash splashed on estate agent's fees"
        canonical="/free-the-funds"
        metaImage={BrandImg}
        noIndex
      />
      <Intro />
      <Video />
      <CompetitionEntriesCarousel />
    </Layout>
  );
};

export default FreeTheFees;
