import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { Box, Text, Flex } from '@strikelabs/luna';

import { Container } from 'components/common';

const Video = () => (
  <Container>
    <Box my={[5, 5, 5, 6]}>
      <Flex justifyContent="center">
        <ReactPlayer url="https://youtu.be/i1_VSZKHxzo" />
      </Flex>
    </Box>
    <Text as="p" ext mb={[5, 5, 5, 3]}>
      Thanks again for all of your entries &mdash; and check out some of the
      winners below:
    </Text>
  </Container>
);

export default Video;
