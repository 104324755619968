import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Dot } from 'pure-react-carousel';
import { Flex } from '@strikelabs/luna';

export const Circle = styled(Dot)`
  border-radius: 100%;
  background-color: ${({ theme, fill }) => `${theme.colors[fill]}`};
  opacity: 0.5;
  transition: all 0.1s ease-in-out;
  border: none;
  margin: ${({ spacing, theme }) =>
    spacing ? `0 ${theme.atomSpacing[spacing]}` : `0 3px`};
  }

  ${({ size }) => {
    switch (size) {
      case 'medium':
        return css`
          height: ${rem(18)};
          width: ${rem(18)};
        `;
      case 'large':
        return css`
          height: ${rem(22)};
          width: ${rem(22)};
        `;
      default:
        return css`
          height: ${rem(12)};
          width: ${rem(12)};
        `;
    }
  }};
`;

export const Container = styled(Flex)`
  button:disabled {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.accent};
  }

  button:focus {
    outline: none;
  }
`;
